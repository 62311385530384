body {
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

a {
  font-size: 12px;
  color: var(--primary-purple) ;
}

:root{
  --primary-purple: #A960EE;
  --purple-opacity:#a960ee80;
  --pink:#F92B75;
  --yellow:#FFCB57;
  --success:#4BB543;
  --lightblue:#90E0FF;
  --title-color:#0A2540;
  --text-color:#425466;
  --light-bg:#F6F9FC;
  --border-color:#E6E6E6;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

.input-style {
  height: 45px;
  width: 30%;
  padding: 1rem;
}

svg.MuiSvgIcon-root {
  margin-bottom: -5xp;
}

h4 {
  font-size: 18px;
  color: #1d1a27;
  font-weight: 500;
}

h5 {
  font-size: 14px;
  color: #c0c0c0;
  font-weight: 300;
}

.react-js-cron span {
  font-size: 13px;
}

.react-js-cron button {
  background-color: transparent !important;
  color: red;
  padding: 0;
  box-shadow: none !important;
}

th{
  border-bottom: none !important;
}

small{
  display: block;
  color: var(--text-color);
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 0.855rem */
letter-spacing: -0.2px;
}

input[type=checkbox]{
  width: 20px;
}

.p-tooltip-arrow{
  border-bottom-color: var(--border-color) !important;
}
.p-tooltip-text{
  background: white !important;
  color: var(--text-color) !important;
  font-family: "Inter" !important;
  font-size: 14px;
  box-shadow: none !important;
  border:1px solid var(--border-color);
}

.p-menu.p-menu-overlay{
  padding:0;
  background: white !important;
  color: var(--text-color) !important;
  font-family: "Lato" !important;
  font-size: 14px;
  box-shadow: none !important;
  border-radius:6px !important;
  border:1px solid var(--border-color)
}
.p-menu .p-menuitem-link:not(.p-disabled):hover{
  background:var(--light-bg);
  text-decoration: underline;
}

.p-menu{
  margin-top: 10px;
  width: fit-content !important;
}

.p-dropdown-panel{
  box-shadow:none;
  margin-top: 10px;
  width:50px;
  border: solid 1px var(--border-color);
  /* border-radius: 1222px; */

  
}

/* .p-dropdown-items-wrapper{
  border-radius: 12px;

} */
.p-dropdown-header {
  background: white !important;
    /* padding: 0; */
    border: none;
    box-shadow: none;
}
.p-dropdown{
  box-shadow: none !important;
}
.p-dropdown-header input{
  border: solid 1px var(--border-color) !important;
  box-shadow:none !important;

}
.p-dropdown-items{

  padding: 0px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: white;
  background: var(--title-color) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--title-color);
  background: var(--light-bg) !important;
}

.tox-tinymce {
  border-radius: 0px !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: white;
  background: var(--primary-purple);
  box-shadow:none;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--primary-purple);
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: white;
  background: var(--primary-purple);;
  color: #ffffff;
}

.p-radiobutton .p-radiobutton-box{
  box-shadow:none !important;
}

.p-dialog-mask.p-component-overlay {
  background-color: transparent !important;
  backdrop-filter: blur(3px);
  pointer-events: auto;
}

.p-dialog{
  box-shadow: none !important;
  border: solid 1px var(--border-color);
}

.p-component{
  font-family: 'Lato', sans-serif !important;
  font-weight: inherit !important;
}

.p-accordion-header-link{
  box-shadow:none !important;
  border:none !important;
  background: white !important;
  color: var(--title-color) !important;
  font-family: 'Lato' !important;
}
.p-accordion-content{
  border:none !important;
}

.p-dialog-footer{
  padding-top:20px !important;
  border-top: solid 1px var(--border-color) !important;
}

.p-dialog{
  background: white;
  padding: 15px;
}

.p-blockui{
  background-color: rgba(255, 255, 255, 0.807) !important;
}

.tox-statusbar__branding{
  display: none !important;
}

.tox.tox-tinymce{
  border: none!important;
}

.tox-editor-header{
  box-shadow: none !important;
    border-bottom: solid 1px var(--border-color) !important;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.375rem 0.75rem;
  margin-right: 5px;
  background: var(--light-bg);
  color: var(--title-color);
  font-size: 12px;
  border-radius: 16px;
  margin-bottom: 10px;
}


.header{
  width:20px;
}
/* 
.tox-editor-header{
  border:none !important;
} */

.tox-statusbar{
  display:none !important;
}

.grecaptcha-badge { visibility: hidden; }

svg.p-icon.p-dropdown-trigger-icon.p-clickable{
  width:0.6em;
  height:0.6em;
}

.p-disabled, .p-component:disabled{
  background: var(--light-bg);
  cursor:not-allowed !important;
  pointer-events: auto;
}

.p-button{
  border-radius:0 !important;
}

/* Switch style */
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--purple-opacity);
  border-color:var(--primary-purple);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: var(--purple-opacity);

}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: var(--primary-purple);
}

.p-inputswitch .p-inputswitch-slider {
  border-color:var(--border-color);
background: white;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}

.p-inputswitch.p-focus .p-inputswitch-slider{
  box-shadow:none !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: var(--border-color);
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: white;

}